import { useMemo } from 'react';
import { useAppSelector } from '@lib/redux/store';
import { useGetUserSubscriptionsQuery } from '@lib/redux/api/monetization-api';
import { userSubscriptionAdapter, userSubscriptionSelector } from '@monetization/service';

export function useIsSubscribedToCreator(userId: string, creatorId: string) {
  const {
    data,
    isLoading: subscriptionLoading,
    refetch,
  } = useGetUserSubscriptionsQuery(
    { user_id: userId, creator_id: creatorId, page: 1, pageSize: 10 },
    {
      skip: !userId || !creatorId,
      selectFromResult: ({ data, ...params }) => {
        return {
          data: {
            ...data,
            user_subscriptions: userSubscriptionSelector.selectAll(
              data ? data.user_subscriptions : userSubscriptionAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
      refetchOnMountOrArgChange: true,
    },
  );

  const subscriptions = useMemo(() => data?.user_subscriptions || [], [data]);
  const hasActiveOrCancelledPendingSubscription = useMemo(() => {
    const hasSubscription = subscriptions.length > 0;
    const hasActiveSubscription = hasSubscription && subscriptions[0].status === 'active';
    const hasCancelledPendingSubscription =
      hasSubscription && subscriptions[0].status === 'cancelled_pending';
    return hasActiveSubscription || hasCancelledPendingSubscription;
  }, [subscriptions]);

  return hasActiveOrCancelledPendingSubscription;
}
