import { Icon, Text } from '@ui/components';
import { SidebarLink } from './sidebar-link';
import { useAppSelector } from '@lib/redux/store';
import { CREATOR_WEB_URL } from '@notification/service';

interface ISidebarLogout {
  onLogout(shouldRedirect?: boolean): void;
}

export function SidebarLogout({ onLogout }: ISidebarLogout) {
  const user = useAppSelector((state) => state.user.user);

  const onTriggerLogout = () => {
    onLogout(false);
    if (user?.displayName) {
      window.location.href = CREATOR_WEB_URL;
    }
  };

  return (
    <div className="h-28 pb-4">
      <div className="border-korDarkGrey mx-10 my-3 border-b" />
      <SidebarLink onClick={onTriggerLogout} className="mx-4 rounded-xl">
        <div className="flex items-center px-4 py-4">
          <Icon type="logout" stroke="#fff" className="mr-2" />
          <Text type="l" className="text-white">
            {user?.displayName ? 'Return to Creator' : 'Exit'}
          </Text>
        </div>
      </SidebarLink>
    </div>
  );
}
