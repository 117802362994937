import { Button, Icon, SidebarLayout } from '@ui/components';
import { SidebarUser, ISidebarUser } from './sidebar-user';
import { SidebarCreator, ISidebarCreator } from './sidebar-creator';
import { SidebarAllCreators } from './sidebar-all-creators';
import { SidebarLogout } from './sidebar-logout';
import { NotificationPanel } from '@notification/component';
import {
  useGetNotificationsQuery,
  useMarkAllAsReadMutation,
  useUpdateNotificationMutation,
} from '@lib/redux/api/notification-api';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import korcrewLogo from '@ui/assets/images/Logo-text-white.png';

interface ISidebar {
  user: ISidebarUser;
  creators: ISidebarCreator[];
  totalBadges: number;
  onLogout(shouldRedirect?: boolean): void;
}

export function SideBar({ user, creators, totalBadges, onLogout }: ISidebar) {
  const router = useRouter();

  const handleSettingsRoute = () => router.push(`${ROUTES.settings}/?tab=notification-preference`);
  return (
    <SidebarLayout wrapperClassName="hidden lg:flex" className="bg-bgBlack rounded-r-xl">
      <div className="mb-6 flex justify-center pt-14">
        <img src={korcrewLogo.src} alt="Korcrew" className="w-[130px]" />
      </div>
      <SidebarUser {...user} />
      <NotificationPanel
        useMarkAllAsReadMutation={useMarkAllAsReadMutation}
        useUpdateNotificationMutation={useUpdateNotificationMutation}
        useGetNotificationsQuery={useGetNotificationsQuery}
        onSettings={handleSettingsRoute}
        side="right"
        user_id={user?.id}>
        {(unread_notifications) => (
          <div className="px-2">
            <Button
              type="notActive"
              className="px-5 flex items-center w-full bg-transparent hover:bg-fanGreyHover active:bg-transparent">
              <div className="flex items-center w-full gap-4 text-primary">
                <Icon type="notification" className="text-primary" stroke="#FF600D" />
                <p className="text-sm text-primary">Notifications</p>
              </div>
              {Boolean(unread_notifications) && (
                <span className="block bg-primary text-white rounded-full text-xs px-2 py-1">
                  {unread_notifications}
                </span>
              )}
            </Button>
          </div>
        )}
      </NotificationPanel>
      <div className="border-korDarkGrey mx-4 my-3 border-b" />
      <div className="scrollbar-thumb-korDarkGrey scrollbar-thin scrollbar-rounded-xl scrollbar-track-bgBlack mr-1 flex flex-grow flex-col overflow-y-auto">
        <div className="flex flex-col gap-2 px-2 pb-2">
          <SidebarAllCreators totalBadges={totalBadges} />
          {creators.map(({ avatar, name, badgeAmount, id, isNotifications }, key) => (
            <SidebarCreator
              key={key}
              avatar={avatar}
              id={id}
              name={name}
              badgeAmount={badgeAmount}
              isNotifications={isNotifications}
            />
          ))}
        </div>
      </div>
      <SidebarLogout onLogout={onLogout} />
    </SidebarLayout>
  );
}
